type TimeDataList = TimeDataMap[];

interface TimeDataMap {
  start: number;
  end: number;
}

interface LectureTimeStampMap {
  [key: string]: TimeDataList;
}

interface LectureMetaDataMap {
  [key: string]: {
    time_data_list: TimeDataList;
    is_header?: boolean;
    chapter_id?: string;
    subject_id?: string;
  };
}

interface TransformedLectureTimeStamp {
  [key: string]: number;
}

interface LecturesWatchedItem {
  id: string;
  time_spent: number;
  is_header?: boolean;
  chapter_id?: string;
  subject_id?: string;
}

interface LecturesWatchedMap {
  [key: string]: LecturesWatchedItem;
}

interface LectureMap {
  lectures_watched: LecturesWatchedMap;
  total_time_spent: number;
}

export class Transform {
  private static transformTimeData(arr: TimeDataList): number {
    let _arr = [...arr];
    return _arr.reduce((acc, cur) => {
      if (cur.end > cur.start) acc += cur.end - cur.start;
      return acc;
    }, 0);
  }

  private static transformLectureTimeStamp(
    dataMap: LectureTimeStampMap
  ): TransformedLectureTimeStamp {
    let _transformDataMap = {};

    for (let key in dataMap) {
      _transformDataMap[key] = Transform.transformTimeData(dataMap[key]);
    }

    return _transformDataMap;
  }

  static shapeLectureMap(dataMap: LectureTimeStampMap): LectureMap {
    const transformedLectureTimeStamp =
      Transform.transformLectureTimeStamp(dataMap);

    let _shapedLectureMap = {
      lectures_watched: {},
      total_time_spent: 0,
    };
    for (let key in transformedLectureTimeStamp) {
      _shapedLectureMap.lectures_watched[key] = {
        id: key,
        time_spent: Math.round(transformedLectureTimeStamp[key]),
      };
      _shapedLectureMap.total_time_spent += transformedLectureTimeStamp[key];
    }

    _shapedLectureMap.total_time_spent = Math.round(
      _shapedLectureMap.total_time_spent
    );

    return _shapedLectureMap;
  }

  private static transformLectureTimeStampWithMetaData(
    dataMap: LectureMetaDataMap
  ): TransformedLectureTimeStamp {
    let _transformDataMap = {};

    for (let key in dataMap) {
      _transformDataMap[key] = Transform.transformTimeData(
        dataMap[key].time_data_list
      );
    }

    return _transformDataMap;
  }

  static shapeLectureMapWithMetaData(dataMap: LectureMetaDataMap): LectureMap {
    const transformedLectureTimeStamp =
      Transform.transformLectureTimeStampWithMetaData(dataMap);

    let _shapedLectureMap = {
      lectures_watched: {},
      total_time_spent: 0,
    };
    for (let key in transformedLectureTimeStamp) {
      _shapedLectureMap.lectures_watched[key] = {
        id: key,
        chapter_id: dataMap[key].chapter_id,
        subject_id: dataMap[key].subject_id,
        is_header: dataMap[key].is_header,
        time_spent: Math.round(transformedLectureTimeStamp[key]),
      };
      _shapedLectureMap.total_time_spent += transformedLectureTimeStamp[key];
    }

    _shapedLectureMap.total_time_spent = Math.round(
      _shapedLectureMap.total_time_spent
    );

    return _shapedLectureMap;
  }
}

export function delay(timeout = 5000) {
  let timer;

  return (fn) => {
    if (timer) return;
    timer = setTimeout(() => {
      fn();
      timer = null;
    }, timeout);
  };
}

export function omitUndefinedKeys(obj: any) {
  let _obj = { ...obj };
  for (let key in _obj) {
    if (_obj[key] === undefined) delete _obj[key];
  }
  return _obj;
}

export function isDestructible(obj): boolean {
  if (!obj) return false;
  if (typeof obj === "object") {
    return true;
  }
  return Array.isArray(obj) && obj.length > 0;
}

export function safeDestructible(obj: any): Record<any, any> {
  if (!obj) return {};
  if (typeof obj === "object") {
    return obj;
  }
  if (Array.isArray(obj) && obj.length > 0) {
    return obj;
  }
  return {};
}
